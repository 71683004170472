import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App);

app.config.globalProperties.appState = [
    {
        activePage: 1,
        activeChapter: 1,
        previousPage: "home",
        nextPage: "2"
    },
    {
        activePage: 2,
        activeChapter: 1,
        previousPage: "1",
        nextPage: "3"
    },
    {
        activePage: 3,
        activeChapter: 1,
        previousPage: "2",
        nextPage: "4"
    },    {
        activePage: 4,
        activeChapter: 1,
        previousPage: "3",
        nextPage: "5"
    },
    {
        activePage: 5,
        activeChapter: 1,
        previousPage: "4",
        nextPage: "6"
    },
    {
        activePage: 6,
        activeChapter: 1,
        previousPage: "5",
        nextPage: "7"
    },
    {
        activePage: 7,
        activeChapter: 1,
        previousPage: "6",
        nextPage: "8"
    },
    {
        activePage: 8,
        activeChapter: 1,
        previousPage: "7",
        nextPage: "9"
    },
    {
        activePage: 9,
        activeChapter: 1,
        previousPage: "8",
        nextPage: "10"
    },
    {
        activePage: 10,
        activeChapter: 1,
        previousPage: "9",
        nextPage: "11"
    },
    {
        activePage: 11,
        activeChapter: 1,
        previousPage: "10",
        nextPage: "12"
    },
    {
        activePage: 12,
        activeChapter: 1,
        previousPage: "11",
        nextPage: "14"
    },
    {
        activePage: 13,
        activeChapter: 1,
        previousPage: "12",
        nextPage: "14"
    },
    {
        activePage: 14,
        activeChapter: 1,
        previousPage: "12",
        nextPage: "15"
    },
    {
        activePage: 15,
        activeChapter: 1,
        previousPage: "14",
        nextPage: "16"
    },
    {
        activePage: 16,
        activeChapter: 2,
        previousPage: "15",
        nextPage: "17"
    },
    {
        activePage: 17,
        activeChapter: 2,
        previousPage: "16",
        nextPage: "18"
    },
    {
        activePage: 18,
        activeChapter: 2,
        previousPage: "17",
        nextPage: "19"
    },
    {
        activePage: 19,
        activeChapter: 2,
        previousPage: "18",
        nextPage: "20"
    },
    {
        activePage: 20,
        activeChapter: 2,
        previousPage: "19",
        nextPage: "21"
    },
    {
        activePage: 21,
        activeChapter: 2,
        previousPage: "20",
        nextPage: "22"
    },
    {
        activePage: 22,
        activeChapter: 2,
        previousPage: "21",
        nextPage: "23"
    },
    {
        activePage: 23,
        activeChapter: 2,
        previousPage: "22",
        nextPage: "24"
    },
    {
        activePage: 24,
        activeChapter: 2,
        previousPage: "23",
        nextPage: "25"
    },
    {
        activePage: 25,
        activeChapter: 2,
        previousPage: "24",
        nextPage: "26"
    },
    {
        activePage: 26,
        activeChapter: 3,
        previousPage: "25",
        nextPage: "27"
    },
    {
        activePage: 27,
        activeChapter: 3,
        previousPage: "26",
        nextPage: "28"
    },
    {
        activePage: 28,
        activeChapter: 3,
        previousPage: "27",
        nextPage: "29"
    },
    {
        activePage: 29,
        activeChapter: 3,
        previousPage: "28",
        nextPage: "30"
    },
    {
        activePage: 30,
        activeChapter: 3,
        previousPage: "29",
        nextPage: "31"
    },
    {
        activePage: 31,
        activeChapter: 3,
        previousPage: "30",
        nextPage: "32"
    },
    {
        activePage: 32,
        activeChapter: 3,
        previousPage: "31",
        nextPage: "33"
    },
    {
        activePage: 33,
        activeChapter: 3,
        previousPage: "32",
        nextPage: "34"
    },
    {
        activePage: 34,
        activeChapter: 3,
        previousPage: "33",
        nextPage: "35"
    },
    {
        activePage: 35,
        activeChapter: 4,
        previousPage: "34",
        nextPage: "36"
    },
    {
        activePage: 36,
        activeChapter: 4,
        previousPage: "35",
        nextPage: "end"
    }
]

app.config.globalProperties.chapters = [
    {
        firstPage: 1,
        links: [
            { title: 'Un mode de déplacement en croissance', page: 1 },
            { title: 'Diversité des EPDMs', page: 4 },
            { title: 'Une nouvelle législation spécifique', page: 11 },
            { title: 'Accidentologie plus graves et fréquents', page: 14 },
        ]
    },
    {
        firstPage: 16,
        links: [
            { title: "Définition d'un EPDM", page: 16 },
            { title: 'Équipements obligatoires', page: 17 },
            { title: 'Où et comment circuler', page: 21 },
            { title: 'Infractions et Contraventions', page: 23 },
        ]
    },
    {
        firstPage: 26,
        links: [
            { title: "Conduites à risques", page: 26 },
            { title: "Cas de conduites à risques", page: 27 },
        ]
    },
    {
        firstPage: 35,
        links: [
            { title: "Équipements obligatoires", page: 35 },
            { title: 'Conseil pratiques', page: 36 },
        ]
    },
];

//app.config.globalProperties.credits = false;

//createApp(App).use(router).mount('#app')
app.use(router).mount('#app')